@import '../../common.scss';

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #e9f5f9;

  .header {
    height: toVh(60);
    width: 100%;
    display: flex;

    .img {
      height: toVh(57);
      margin-top: toVh(10);
      margin-left: toVw(30);
    }

    &>span {
      color: rgba(0, 63, 136, 1);
      font-size: toVh(16);
      font-family: SourceHanSansSC-medium;
      align-self: end;
    }
  }

  .main {
    position: absolute;
    bottom: toVh(12);
    left: toVw(20);
    width: calc(100% - toVw(40));
    height: calc(100% - toVh(92));
    border-radius: toVh(10);
    background-color: #fff;

    .title {
      position: absolute;
      top: toVh(30);
      left: 50%;
      transform: translateX(-50%);
      font-size: toVh(20);
      color: rgba(0, 63, 136, 1);
      font-family: SourceHanSansSC-medium;
      align-self: end;
    }

    .form {
      position: absolute;
      top: toVh(120);
      left: toVw(30);
      width: calc(100% - toVw(60));

      .row {
        display: flex;
        align-items: center;

        &>span {
          display: inline-block;
          color: rgba(0, 63, 136, 1);
          font-size: toVh(18);
          font-family: SourceHanSansSC-regular;
          letter-spacing: toVw(10);
        }

        &>div {
          flex: 1;
          margin-left: toVw(12);
          border-bottom: 1px solid rgba(187, 187, 187, 0.7);

          &>input {
            border: none;
            outline: none;

            &::placeholder {
              color: rgba(142, 142, 142, 0.7);
            }
          }
        }
      }

      .password {
        margin-top: toVh(28);
      }

      .submit {
        width: 100%;
        height: toVh(40);
        border-radius: toVh(40);
        background-color: rgba(9, 109, 217, 1);
        color: rgba(255, 255, 255, 1);
        font-size: toVh(16);
        margin-top: toVh(80);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}