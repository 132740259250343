@import '../../common.scss';

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #e9f5f9;

  .header {
    height: toVh(60);
    width: 100%;
    display: flex;

    .img {
      height: toVh(57);
      margin-top: toVh(10);
      margin-left: toVw(30);
    }

    &>span {
      color: rgba(0, 63, 136, 1);
      font-size: toVh(16);
      font-family: SourceHanSansSC-medium;
      align-self: end;
    }
  }

  .main {
    margin-top: toVh(20);
    margin-left: toVw(20);
    margin-bottom: toVh(12);
    width: calc(100% - toVw(40));
    border-radius: toVh(10);

    .card {
      display: flex;
      flex-direction: column;
      // height: toVh(200);
      width: 100%;
      border-radius: toVh(10);
      overflow: hidden;
      background-color: #fff;

      &:nth-child(n+2) {
        margin-top: toVh(20);
      }

      &>img {
        width: 100%;
        // height: toVh(150);
      }

      .desc {
        // height: toVh(50);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: toVh(12) toVw(20);
        color: rgba(0, 63, 136, 1);
        font-size: toVw(16);
        font-family: SourceHanSansSC-regular;
      }
    }
  }

  .logout {
    margin-top: toVh(20);
    width: calc(100% - toVw(130));
    height: toVh(40);
    border-radius: toVh(40);
    background-color: #003f88;
    color: rgba(255, 255, 255, 1);
    font-size: toVh(16);
    margin-top: toVh(60);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: toVh(150);
  }

  .bottom_img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
  }
}