@import "./common.scss";

.switch {
  position: absolute;
  top: 8px;
  right: 10px;
  transform: scale(0.8);
}

html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  // max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  padding: 0 !important;
}

.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // max-width: 500px;
}

* {
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;


}

input,
textarea {
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;
}