.container {
  height: 100vh;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    &>span {
      margin: 0 12px;
      width: 100px;
      text-align: center;
      display: inline-block;
    }

    .account,
    .num {
      width: 40px;
    }

    .time {
      width: 200px;
    }
  }

  .body {
    overflow: auto;
    height: calc(100% - 60px);
    margin-bottom: 20px;

    .row {
      display: flex;
      align-items: center;
      justify-content: center;

      &>span {
        margin: 0 12px;
        width: 100px;
        text-align: center;
        display: inline-block;
      }

      .account,
      .num {
        width: 40px;
      }

      .time {
        width: 200px;
      }
    }
  }
}